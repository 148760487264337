import { httpsCallable } from "firebase/functions";
import { cloudFunctions } from "./firebase";

export const newLogin = httpsCallable(cloudFunctions, "newLoginV2");

export const verifyLogin = httpsCallable(cloudFunctions, "verifyLoginV2");

export const getAvailableProducts = httpsCallable(cloudFunctions, "getAvailableProductsV2");

export const getUpcomingTasks = httpsCallable(cloudFunctions, "getUpcomingTasksV2");

export const deleteTask = httpsCallable(cloudFunctions, "deleteTaskV2");

export const enqueuePricePush = httpsCallable(cloudFunctions, "enqueuePricePushV2");

export const testing = httpsCallable(cloudFunctions, "testingV2");

